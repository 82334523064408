import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoHelperService {

  constructor() { }
 //The set method is use for encrypt the value.
 encrypt(keys, value) {
  var key = CryptoJS.enc.Utf8.parse(keys);
  var iv = CryptoJS.enc.Utf8.parse(keys);
  var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value), key,
          {
              keySize: 128 / 8,
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7
          });
  return encrypted.toString();
}

//The get method is use for decrypt the value.
decrypt(keys, value) {
  var _key = CryptoJS.enc.Utf8.parse(keys);
  var _iv = CryptoJS.enc.Utf8.parse(keys);

  var decrypted = CryptoJS.AES.decrypt(value, _key, {
    keySize: 128 / 8,
    iv: _iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}
}
