import { NgModule, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AppComponent } from '../app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApplicationInsightsModule, AppInsightsService } from '@markpieszak/ng-application-insights';
import { GetProtectedResourceMapForMSALInterceptorConfigFactory, GetPublicClientApplicationForMSALInstanceFactory } from '../constants/msal.config';
import { CryptoHttpInterceptorService } from '../interceptors/crypto-http-interceptor.service';
import { IPublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { AuthGuard } from '../guards/auth.guard';
import { ResidentprofileComponent } from '../components/residentprofile/residentprofile.component';

export function MSALInstanceFactory(): IPublicClientApplication {
  return GetPublicClientApplicationForMSALInstanceFactory();
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = GetProtectedResourceMapForMSALInterceptorConfigFactory();
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
export const loginRequest = {
  scopes: ['openid', 'offline_access'],
  prompt: 'select_account'
};
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

const route: Routes = [
  { path: '', component: LoginComponent },
  {
    // Needed for hash routing
    path: 'error',
    component: AppComponent
  },
  {
    // Needed for hash routing
    path: 'state',
    component: AppComponent

  },//canActivate: []
  {
    path: 'entrata',
    loadChildren: () => import('././../entrata/entrata.module').then(x => x.EntrataModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./../app.module').then(x => x.AppModule),
    canActivate: [AuthGuard]
  }  //canActivate: []
];

const isIframe = window !== window.parent && !window.opener;
@NgModule({
  declarations: [
    LoginComponent,
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MsalModule,
    RouterModule.forRoot(route, {
      useHash: false,
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabled' : 'disabled'
    }),
    //MsalModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.appInsights.instrumentationKey
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    { provide: HTTP_INTERCEPTORS, useClass: CryptoHttpInterceptorService, multi: true },
    AppInsightsService,
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class LoginModule { }
