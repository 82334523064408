import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import{ CryptoHelperService } from '../services/crypto-helper.service';
import { environment } from '../../environments/environment';
import { map, catchError, tap } from 'rxjs/operators';
//import { forEach } from '@angular/router/src/utils/collection';

@Injectable({
  providedIn: 'root'
})
export class CryptoHttpInterceptorService implements HttpInterceptor{ 

  constructor(private cryptoHelper: CryptoHelperService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

   var reqestUrl = req.url;
   var apiUrl = '';
   var baseUri = environment.apiBaseUrl + '/' ;
   var index = reqestUrl.lastIndexOf(baseUri);
   if(index != -1)
   {
   var apiEndpoint = reqestUrl.substring(index+baseUri.length);
   var encrypted = this.cryptoHelper.encrypt(environment.cryptoKey, apiEndpoint);
   var m_encrypted = encrypted.toString().replace(/\//g, "_").replace(/\+/g, "-");  
    
   apiUrl = `${environment.apiBaseUrl}/${m_encrypted}`;     
  
  let modifiedReq = req.clone({ url: apiUrl});
  if (req.params && req.params.keys() && req.params.keys().length > 0) {
    var encParams = this.cryptoHelper.encrypt(environment.cryptoKey, req.params.toString());
    var m_encParams = encParams.toString().replace(/\//g, "_").replace(/\+/g, "-");
    modifiedReq = req.clone({ url: apiUrl, params: new HttpParams(),  setParams: {"q":m_encParams}});
  }
   return next.handle(modifiedReq);   
   }
   return next.handle(req);
  }  
}
